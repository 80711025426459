import { isAfter } from 'date-fns'

import { required, requiredIf } from '@vuelidate/validators'
import { contractStartDate, dateOfBirth } from '@/validations/components'
import { DateTimeUtil } from '@/utils/DateTime'

import partnerStore from '@/store/partner'

const validations = person => ({
  collectivePersonGroup: {
    required: requiredIf(function () {
      return partnerStore.partner.collectiveContractConfig?.personGroups?.length > 0
    }),
  },
  preInsurer: {
    currentRegistration: {
      required: requiredIf(() => {
        return person.immigrant
      }),
      ...contractStartDate,
      notPreBorn: value => {
        return !isAfter(DateTimeUtil.parseDate(person.personData.dateOfBirth), DateTimeUtil.parseDate(value))
      },
    },
  },
  personData: {
    dateOfBirth,
    firstName: {
      //...omit(firstName, ['required']),
    },
    gender: {
      required,
    },
  },
})

export default validations
