<template>
  <!-- prettier-ignore -->
  <div
    ref="personRef"
    cy-section="person-form"
    :class="{'card--disabled': isLocked}"
  >
    <template v-if="!$props.isExistingCustomer && !isUnborn">
      <the-section class="contract-date">
        <div class="person__immigrant">
          <Form.Toggle
            v-model="isImmigrant"
            class="contract-date__toggle"
            name="immigrant"
            :title-label="$t('screen.welcome.immigrant.title')"
          />
          <the-icon
            v-tooltip="{ trigger: 'click', content: $t('screen.welcome.immigrant.tooltip') }"
            class="contract-date__tooltip"
            art="light"
            html-tooltip
            name="question-circle"
          />
        </div>
        <transition
          appear
          mode="out-in"
          name="slide-down"
          @after-enter="afterEnter"
          @enter="enter"
          @leave="leave"
        >
          <div
            v-if="isImmigrant && $props.person.preInsurer"
            class="contract-date--inline"
          >
            <Form.DateInput
              :key="`${$props.person.personId}-${isImmigrationAutofocus}`"
              v-model="$props.person.preInsurer.currentRegistration"
              class="contract-date__date"
              is-native
              name="$props.contractStartDate"
              required
              :autofocus="isImmigrationAutofocus"
              :placeholder="$t('form.dateHint')"
              :theme="$props.theme"
              :title-label="$t('screen.personalData.preInsurer.currentRegistration')"
              :validate="v.preInsurer.currentRegistration"
            />

            <the-message
              theme="dark"
              :text="$t('screen.welcome.immigrant.message')"
            />
          </div>
        </transition>
      </the-section>
    </template>

    <the-section>
      <Form.Gender
        v-model="$props.person.personData.gender"
        name="gender"
        :age="age"
        :disabled="isDisabled"
        :readonly="isDisabled"
        :validate="v.personData.gender"
      />
    </the-section>

    <template v-if="groups.length > 0">
      <the-section>
        <Form.Dropdown
          v-model="$props.person.collectivePersonGroup"
          class="person__groups"
          name="collectivePersonGroup"
          required
          :options="groups"
          :theme="$props.theme"
          :title-label="$t('person.collective.group')"
          :validate="v.collectivePersonGroup"
        />
      </the-section>
    </template>

    <the-section>
      <div class="person__entry">
        <div class="person__data">
          <Form.DateInput
            v-model="$props.person.personData.dateOfBirth"
            :class="{'input__unborn': isUnborn}"
            is-active
            name="dateOfBirth"
            :delay="0"
            :disabled="isDisabled"
            :placeholder="$t('form.dateHint')"
            :theme="$props.theme"
            :title-label="birthdateText"
            :validate="v.personData.dateOfBirth"
          />

          <Form.Input
            v-model="$props.person.personData.firstName"
            :class="{'input__unborn': isUnborn}"
            is-active
            name="firstName"
            :disabled="isDisabled"
            :placeholder="$t('person.person', { number: $props.index + 1 })"
            :theme="$props.theme"
            :title-label="firstnameText"
            :validate="v.personData.firstName"
          />
          <Form.Input
            v-if="$props.person.personData.lastName && !isAide"
            v-model="$props.person.personData.lastName"
            is-active
            name="lastName"
            :disabled="isDisabled"
            :theme="$props.theme"
            :title-label="$t('person.lastname')"
            :validate="v.personData.lastName"
          />
        </div>
        <div class="person__actions">
          <Form.Button
            v-if="isLocked"
            class="person__action-item"
            plain
          >
            <div
              v-tooltip="$t('person.readonlyInfo')"
              class="action__content"
            >
              <the-icon
                art="light"
                name="lock-keyhole"
              />
              <the-typography
                v-if="!browser.isDesktop"
                tag="span"
                type="bodyLargeLong"
                v-text="$t('product.detail.locked.button')"
              />
            </div>
          </Form.Button>
          <Form.Button
            v-else-if="isDeletable"
            class="person__action-item"
            plain
            @click="$emit('delete')"
          >
            <div class="action__content">
              <the-icon
                art="solid"
                name="trash"
              />
              <the-typography
                v-if="!browser.isDesktop"
                tag="span"
                type="bodyLargeLong"
                v-text="$t('product.actions.remove')"
              />
            </div>
          </Form.Button>
        </div>
      </div>
      <the-message
        v-if="isUnborn"
        class="person__unborn__message"
        theme="dark"
        :text="$t('person.unbornMessage')"
      />
    </the-section>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'

import isNil from 'lodash/isNil'
import useVuelidate from '@vuelidate/core'

import useHeightTransition from '@/directives/HeightTransition'
import validations from '@/validations/contractPerson'
import { DateTimeUtil } from '@/utils/DateTime'

import useApplication from '@/hooks/useApplication'
import useBrowser from '@/hooks/useBrowser'
import useI18n from '@/hooks/useI18n'

import * as Form from '@/components/Form'

import { GENDER, ROUTE_URL_PARAMS } from '@/config/constants'

// HOOKS
const { isAide, isWww } = useApplication()
const { browser } = useBrowser()
const { enter, afterEnter, leave } = useHeightTransition()
const { t } = useI18n()

// INIT
defineEmits(['delete'])
const props = defineProps({
  contractStartDate: {
    type: String,
    required: true,
  },
  index: {
    type: Number,
    required: true,
  },
  isExistingCustomer: {
    type: Boolean,
    default: false,
  },
  person: {
    type: Object,
    required: true,
  },
  personGroups: {
    type: Array,
    default: () => [],
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
  theme: {
    type: String,
    default: 'light',
  },
})

// DATA
const isImmigrant = ref(props.person.immigrant)
const isImmigrationAutofocus = ref(false)
const personRef = ref(undefined)

// COMPUTED
const age = computed(() => {
  const _age = DateTimeUtil.getAge(props.person.personData.dateOfBirth)
  const firstName = props.person.personData.firstName
  // watcher on `age` didn't work for some reason...
  if (_age < 0 && (isNil(firstName) || firstName === '')) {
    // eslint-disable-next-line
    props.person.personData.firstName = `${t('person.defaultBabyName')} ${props.index + 1}`
  }

  return _age
})

const birthdateText = computed(() => {
  return isUnborn.value ? t('person.estimatedBirthdate') : t('person.birthdate')
})

const firstnameText = computed(() => {
  return isUnborn.value ? t('person.provisionalFirstname') : t('person.firstname')
})

const groups = computed(() => {
  return props.personGroups.map(group => {
    return {
      value: group.groupNumber,
      text: group.name,
    }
  })
})

const isDeletable = computed(() => {
  if (!props.readOnly && (!props.isExistingCustomer || !props.person.partnerNumber))
    return props.person.personData.dateOfBirth || props.index > 0
  return false
})

const isDisabled = computed(() => {
  return props.readOnly || (props.isExistingCustomer && !!props.person.partnerNumber)
})

const isLocked = computed(() => {
  return props.readOnly || (props.isExistingCustomer && !props.person.contractModified)
})

const isUnborn = computed(() => {
  return props.person.personData.gender === GENDER.UNBORN
})

// METHODS
function checkRouteParams() {
  if (isWww.value) return

  const route = useRoute()

  const { personId, fieldName } = route.params
  let target = personRef.value

  if (personId && props.person.personId === personId) {
    try {
      if (fieldName) {
        const field = personRef.value.querySelector(`[name=${fieldName}]`)
        target = field.closest('.section')

        if (fieldName === ROUTE_URL_PARAMS.IMMIGRANT) {
          isImmigrant.value = true
          isImmigrationAutofocus.value = true
        } else {
          field.focus()
        }
        scrollToElement(target)
      }
    } catch (e) {
      // report error to Instana
      if (window.ineum) {
        window.ineum('reportError', `Error at focusing "${target}" for field "${fieldName}" on screen person`)
      }
    }
  }
}

function processImmigration() {
  /* eslint-disable vue/no-mutating-props */
  props.person.preInsurer = props.person.preInsurer || {}
  if (isImmigrant.value) {
    props.person.preInsurer.preInsurer = false
    props.person.immigrant = true
    isImmigrationAutofocus.value = true
  } else {
    props.person.preInsurer.currentRegistration = null
    props.person.preInsurer.preInsurer = true
    props.person.kvgContractStartDate = props.contractStartDate
    props.person.immigrant = false
    v.value.$touch()
  }
  /* eslint-enable vue/no-mutating-props */
}

function scrollToElement(element) {
  if (!element?.scrollIntoView) {
    throw new Error()
  }

  // Wait until the animations are over
  setTimeout(() => {
    element.scrollIntoView({
      behavior: 'smooth',
      alignToTop: true,
    })
  }, 500)
}

// VALIDATION
const v = useVuelidate(validations(props.person), props.person, { $lazy: true })

// WATCHERS
watch(isImmigrant, () => {
  processImmigration()
})
watch(
  () => props.person.preInsurer?.currentRegistration,
  () => {
    if (props.person.preInsurer?.currentRegistration) {
      /* eslint-disable-next-line vue/no-mutating-props */
      props.person.kvgContractStartDate = props.person.preInsurer.currentRegistration
    }
  }
)
watch(isUnborn, value => {
  if (value) {
    isImmigrant.value = false
  }
})

// LIFECYCLE HOOKS
onMounted(() => {
  checkRouteParams()
})
</script>

<style name="animations" scoped>
.slide-down-enter-active,
.slide-down-leave-active,
.slide-up-enter-active,
.slide-up-leave-active {
  transition-duration: 0.5s;
  transition-property: height, opacity, transform;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  overflow: hidden;
}

.slide-down-enter,
.slide-up-leave-active,
.slide-down-leave-active,
.slide-up-enter {
  opacity: 0;
  height: 0;
  transform: translate(0, -2em);
}
</style>

<style name="mobile" scoped>
.card--disabled {
  opacity: 0.7;
}

.person__groups {
  max-width: 451px;
}

.person__entry {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;
}

.person__immigrant {
  display: flex;
  flex-direction: row;
}

.person__data {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .input__unborn {
    min-width: 280px;
  }
}

.person__unborn__message {
  font-size: 12px;
  margin: 8px 0 0 0;
}

.person__action-item {
  font-size: 20px;
  margin: 0;
}

.person__action:hover :deep(.icon) {
  color: var(--c-primary-color-2);
}

.action__content {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}

.contract-date {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.contract-date--inline {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.contract-date__date {
  flex: 1 0 auto;
}

.contract-date__tooltip {
  cursor: pointer;
  margin: auto 8px;
}

.contract-date :deep(label.contract-date__toggle) {
  height: 50px;
  padding: 20px 0 10px 5px;
}
</style>

<style name="desktop" scoped>
@media (--md) {
  .person__entry {
    flex-direction: row;
    justify-content: space-between;
  }

  .person__data {
    flex-direction: row;
  }

  .person__actions {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .person__action-item {
    min-height: 0;
    height: auto;
  }

  .contract-date--inline {
    flex-direction: row;
  }

  .contract-date--inline {
    gap: 32px;
  }

  .contract-date__date {
    flex: 0 0 auto;
    width: 230px;
  }

  .contract-date :deep(label.contract-date__toggle) {
    width: 250px;
    margin: 0 0 20px 0;
  }
}
</style>
